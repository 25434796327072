/**
 * Chatbot Script
 *
 * Wrap it in an IIFE to avoid polluting the global scope
 *
 * @see https://developer.mozilla.org/en-US/docs/Glossary/IIFE
 */

import { ChatbotMessageEvent } from '~/utils/message-event'
;(() => {
  const APP_URL = process.env.APP_URL
  const APP_VERSION = process.env.APP_VERSION

  const language = window.location.hostname.includes('renault.hr')
    ? 'HR'
    : 'SLO'

  const IFRAME_URL = `${APP_URL}/chat?language=${language}`

  console.log(`Renault Chatbot v${APP_VERSION || '0.2.5'}`)

  let styles: HTMLStyleElement
  let button: HTMLButtonElement
  let iframe: HTMLIFrameElement

  const css = `
.chatbot-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: fixed;
  bottom: 4rem;
  right: 1rem;
  background-color: #efdf00;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  color: black;
}
.chatbot-button:hover {
  background-color: #e9d900;
}
.chatbot-button--closed {
  display: none;
}
.chatbot-badge {
  position: absolute;
  top: -0.75rem;
  left: -0.75rem;
  background-color: #000;
  color: #fff;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 700;
  font-family: NouvelR-Bold;
}
.chatbot-iframe {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: #fff;
  border: 1px solid #BBBCBC;
  transition: opacity .25s ease-out;
}
.chatbot-iframe--open {
  opacity: 1;
  pointer-events: auto;
  z-index: 2147483647;
}
@media (min-width: 480px) {
  .chatbot-iframe {
    bottom: 1rem;
    right: 1rem;
    width: 448px;
    height: 768px;
    overflow: hidden;
  }
}`

  function createStyles() {
    styles = document.createElement('style')
    parent.document.head.appendChild(styles)
    styles.setAttribute('type', 'text/css')
    styles.appendChild(document.createTextNode(css))
  }

  function createButton() {
    const badge = document.createElement('span')
    badge.setAttribute('class', 'chatbot-badge')
    badge.innerHTML = `<span>AI</span>`

    const icon = document.createElement('span')
    icon.setAttribute('class', 'chatbot-icon')
    icon.innerHTML =
      '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.9493 23.9934C10.5037 24.0324 10.0602 23.8973 9.71087 23.6161C9.36154 23.3348 9.13319 22.9291 9.07317 22.483C5.62101 21.8259 3.56473 19.7264 3.27955 16.6073C1.22326 16.2826 0 14.6135 0 12.076C0 9.21371 1.50094 7.54467 4.06754 7.54467H4.54034V7.1444C4.54034 2.61307 7.30957 0 11.9925 0C16.6754 0 19.4972 2.57531 19.4972 7.1444V7.54467H19.9625C22.5216 7.54467 24 9.21371 24 12.076C24 14.9383 22.379 16.6829 19.7824 16.6829H18.0113V7.20482C18.0113 3.54199 15.9174 1.5482 12.0075 1.5482C8.09756 1.5482 6.00375 3.57975 6.00375 7.20482V16.7055H4.82552C5.08068 19.024 6.48405 20.3986 9.14822 20.9046C9.25916 20.5231 9.50117 20.1937 9.83107 19.9751C10.161 19.7566 10.5573 19.6631 10.9493 19.7113C12.6454 19.7113 13.5084 20.4665 13.5084 21.8863C13.5084 23.3062 12.6079 23.9934 10.9493 23.9934ZM4.54034 15.18V9.13819H4.06754C2.30394 9.13819 1.47092 10.12 1.47092 12.1138C1.47092 14.1076 2.37148 15.1951 4.06754 15.1951L4.54034 15.18ZM19.4897 9.13819V15.18H19.7749C21.576 15.18 22.5141 14.0924 22.5141 12.0987C22.5141 10.1049 21.6135 9.12308 19.7749 9.12308L19.4897 9.13819Z" fill="currentColor"/></svg>'

    button = document.createElement('button')

    button.appendChild(badge)
    button.appendChild(icon)

    button.type = 'button'
    button.className = 'chatbot-button'
    button.addEventListener('click', openChat)
    parent.document.body.appendChild(button)
  }

  function createIframe() {
    if (iframe) return
    iframe = document.createElement('iframe')
    iframe.src = IFRAME_URL
    iframe.className = 'chatbot-iframe'
    parent.document.body.appendChild(iframe)
  }

  function iframeReady() {
    button?.classList.add('chatbot-button--closed')
    iframe?.classList.add('chatbot-iframe--open')
  }

  function closeChat() {
    iframe?.classList.remove('chatbot-iframe--open')
    if (button) {
      button.classList.remove('chatbot-button--closed')
    } else {
      createButton()
    }
  }

  function pushToDataLayer(payload: DataLayerPushPayload) {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push(payload)
  }

  function openChat() {
    if (iframe) {
      button?.classList.add('chatbot-button--closed')
      iframe.classList.add('chatbot-iframe--open')
    } else {
      createIframe()
    }

    pushToDataLayer({
      event: 'chatbot_open',
    })
  }

  function onReady() {
    if (
      document.readyState === 'complete' ||
      document.readyState === 'interactive'
    ) {
      createStyles()
      createButton()
    }
  }

  function onMessage(e: ChatbotMessageEvent) {
    if (!e || !e.data || typeof e.data !== 'object') return

    if ('chatbot' in e.data) {
      const { chatbot } = e.data

      switch (chatbot) {
        case 'ready':
          iframeReady()
          break
        case 'close':
          closeChat()
          break
        case 'data-layer-push': {
          const { message } = e.data

          pushToDataLayer(message)

          break
        }
      }
    }
  }

  parent.document.addEventListener('readystatechange', onReady, { once: true })

  window.addEventListener('message', onMessage)
})()
